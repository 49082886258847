import React from "react";
import { Box, makeStyles, Typography, Slider } from "@material-ui/core";
import StyledCheckbox from "src/component/StyledCheckbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "280px",
    padding: "15px",
    borderRadius: "15px",
    backgroundColor: "#1c2560",
    "& div": {
      "& label": {
        color: "#fff",
        padding: "10px 0px",
      },
      "& h6": {
        color: "#fff",
        alignItems: "center",
        paddingLeft: "15px",
      },
    },
  },
}));

function SoundSetting() {
  const [volume, setVolume] = React.useState(30);
  const [musicVolume, setMusicVolume] = React.useState(30);
  const [notificationVolume, setNotificationVolume] = React.useState(30);

  const handleChange = (event, newValue) => {
    setVolume(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setMusicVolume(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setNotificationVolume(newValue);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box>
        <label>Effect volume:</label>
        <Slider
          value={volume}
          onChange={handleChange}
          aria-labelledby="continuous-slider"
        />
      </Box>
      <Box>
        <label>Music volume:</label>
        <Slider
          value={musicVolume}
          onChange={handleChange1}
          aria-labelledby="continuous-slider"
        />
      </Box>
      <Box>
        <label>Notification volume:</label>
        <Slider
          value={notificationVolume}
          onChange={handleChange2}
          aria-labelledby="continuous-slider"
        />
      </Box>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <StyledCheckbox style={{ padding: "10px 0px", color: "#fff" }} />
        <Typography variant="h6">Mute Sounds</Typography>
      </Box>
    </div>
  );
}

export default SoundSetting;

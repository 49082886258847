import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import SettingsIcon from "@material-ui/icons/Settings";
import { GiCancel } from "react-icons/gi";
import Setting from "src/component/Setting";
import SoundSetting from "src/component/SoundSetting";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";

import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  InputBase,
  Button,
  Modal,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    flexGrow: 1,
    width: "100%",
    backgroundAttachment: "scroll",
    backgroundImage: `url(${process.env.PUBLIC_URL + "/images/main_bg.png"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    opacity: "0.999999",
    "&::before": {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "-1",
      backgroundColor: "rgb(20,26,69)",
    },
  },
  headsec: {
    flexGrow: 1,
    backgroundAttachment: "scroll",
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/home/pokemon_bg.png"
    })`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "relative",
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  pokimg: {
    width: "100%",
    position: "relative",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  homeicon: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    "& h2": {
      fontSize: "20px",
      color: "#e6f4ff",
      fontFamily: "Montserrat",
      textAlign: "center",
      paddingLeft: "5px",
    },
  },
  gen8: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#5F1E9D",
    borderRadius: "10px 10px 0px 0px",
    "& h6": {
      fontSize: "15px",
      letterSpacing: "0px",
      color: "#e6f4ff",
      fontWeight: "600",
      fontFamily: "Montserrat",
      textAlign: "center",
    },
    "& p": {
      fontSize: "12px",
      color: "#e6f4ff",
      fontFamily: "Montserrat",
      textAlign: "center",
    },
  },
  tournament: {
    width: "100%",
    height: "auto",
    borderRadius: "10px",
    backgroundColor: "#601a9f",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    "& h3": {
      fontSize: "27px",
      letterSpacing: "1px",
      lineHeight: "50px",
      color: "#e6f4ff",
      fontWeight: "bold",
      fontFamily: "Malgun Gothic Bold",
      textAlign: "center",
    },
  },
  menuicons: {
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "inherit",
  },
  botomlink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#5F1E9D",
    borderRadius: "10px 10px 0px 0px",
    "& Button": {
      color: " #e6f4ff",
      padding: "8px 0px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "25px",
  },
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userNameIn: {
    border: "1px solid #000",
    padding: "8px",
    borderRadius: "8px",
  },
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  dialogBox: {
    padding: "30px",
  },
  ButtonModalBox: {
    display: "flex",
    marginTop: "10px",
    "& button": {
      border: "1px solid #000",
      borderRadius: "6px",
    },
  },
}));

function Header() {
  const [sound, setSound] = useState(false);
  const [setting, setSetting] = useState(false);
  const [battlePop, setBattlePop] = useState(false);
  const { account } = useWeb3React();
  const [ChatUser, setChatUser] = useState(false);
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();
  const history = useHistory();
  return (
    <div className={classes.header}>
      <Box className={classes.headsec}>
        <Box className={classes.pokimg}>
          <img src="/images/home/pokemon.png" alt="pokemon" width="50%" />
        </Box>
        <Container>
          <Box className={classes.mainbox}>
            <Grid container spacing={2} align="center">
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Box className={classes.homeicon}>
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <Button>
                            <HomeIcon style={{ color: "#fff" }} />
                            <Typography variant="h2">Home</Typography>
                          </Button>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <Box className={classes.gen8}>
                        <Typography variant="h6">
                          [Gen 8] Monotype Random Battle
                        </Typography>
                        <Typography variant="body2">
                          thecaptain01 vs. Auroro5
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <Box className={classes.botomlink}>
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Button
                                onClick={() => history.push("/tournament")}
                              >
                                Tournament
                              </Button>
                              <Button disabled>Help</Button>
                              <Button disabled>Lobby</Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  window.open("https://www.youtube.com/")
                                }
                              >
                                YouTube
                              </Button>
                              <Button onClick={() => history.push("/battle")}>
                                Battle
                              </Button>
                              <Button>+</Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Box className={classes.menuicons}>
                        <Box style={{ alignItems: "center", margin: "auto" }}>
                          {!account ? (
                            <TextField
                              style={{
                                fontFamily: "Montserrat",
                                color: "white",
                              }}
                              className={classes.margin}
                              onClick={() => setBattlePop(true)}
                              inputProps={{}}
                              variant="outlined"
                              id="mui--provider-outlined-input"
                              placeholder="Choose Name"
                            />
                          ) : (
                            <Button fullWidth>Disconnected</Button>
                          )}
                        </Box>
                        <Button onClick={() => setSound(!sound)}>
                          <VolumeUpIcon style={{ color: "#fff" }} />
                        </Button>
                        <Button onClick={() => setSetting(!setting)}>
                          <SettingsIcon style={{ color: "#fff" }} />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Modal
                    open={sound}
                    onClose={() => setSound(false)}
                    className={classes.modal}
                  >
                    <SoundSetting />
                  </Modal>
                  <Modal
                    open={setting}
                    onClose={() => setSetting(false)}
                    className={classes.modal}
                  >
                    <Setting />
                  </Modal>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Dialog
            open={battlePop}
            onClose={() => setBattlePop(false)}
            className={classes.dialog}
          >
            <DialogActions>
              <IconButton
                onClick={() => setBattlePop(false)}
                className={classes.customizedButton}
              >
                <GiCancel />
              </IconButton>
            </DialogActions>
            <DialogContent className={classes.dialogBox}>
              <Box className="modal_text">
                <Typography>
                  <b>Username:</b>
                </Typography>
                <InputBase className={classes.userNameIn} />
              </Box>
              <Box className={classes.ButtonModalBox}>
                <Button style={{ marginRight: "10px" }}>
                  <b>Choose name</b>
                </Button>
                <Button onClick={() => setBattlePop(false)}>Cancel</Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Container>
      </Box>
    </div>
  );
}

export default Header;

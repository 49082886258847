import React from "react";
import {
  // Grid,
  Box,
  // Container,
  // Typography,,
  makeStyles,
  // Button,
} from "@material-ui/core";

import {} from "react-feather";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#5F1E9D",
    position: "relative",
    padding: "40px 0px",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return <Box className={classes.footerSection}></Box>;
}

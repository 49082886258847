export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 42;
export const tokenContract = "0xE4861c8C1A80250e1e280F7F7bbf84736146b867";
export const MarketplaceContract = "0xf5DE7F4Ee0C4063a1e6f139fEa2eB92c2D153653";
export const NftTokenAddress = "0x4846666e4013A48647be98AF3FDE33251e679bd2";

export const users = [
  {
    name: "Chirag",
    color: "blue",
    message: "The thing about it’s ability is that it keeps golisopod alive",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "that pokemon is so cute",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message: "And the next Pokémon you switch in doesn’t get hurt",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "so it’s not pointless",
  },
  {
    name: "Anil",
    color: "red",
    message: "i was watching a battle and i got a random mcafee popup",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "they didnt even try to make it real",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "rip bozo",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "what if i want to keep pod in tho..",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "We're not saying it's not pointless",
  },
  {
    name: "Chirag",
    color: "blue",
    message: "well some situations are better than others",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "Just something you need to play around or not use Goliso",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message:
      "goliso could have some niche starts or something with its ability idk",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "First impression is good imo",
  },
  {
    name: "Anil",
    color: "red",
    message: "yes",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "thats tru",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "First impression is nice, but it's pretty predictable.",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "but that’s the only thing it has going for it",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "how long goes dynamax last",
  },
  {
    name: "Chirag",
    color: "blue",
    message: "The thing about it’s ability is that it keeps golisopod alive",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "that pokemon is so cute",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message: "And the next Pokémon you switch in doesn’t get hurt",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "so it’s not pointless",
  },
  {
    name: "Anil",
    color: "red",
    message: "i was watching a battle and i got a random mcafee popup",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "they didnt even try to make it real",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "rip bozo",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "what if i want to keep pod in tho..",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "We're not saying it's not pointless",
  },
  {
    name: "Chirag",
    color: "blue",
    message: "well some situations are better than others",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "Just something you need to play around or not use Goliso",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message:
      "goliso could have some niche starts or something with its ability idk",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "First impression is good imo",
  },
  {
    name: "Anil",
    color: "red",
    message: "yes",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "thats tru",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "First impression is nice, but it's pretty predictable.",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "but that’s the only thing it has going for it",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "how long goes dynamax last",
  },
  {
    name: "Chirag",
    color: "blue",
    message: "The thing about it’s ability is that it keeps golisopod alive",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "that pokemon is so cute",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message: "And the next Pokémon you switch in doesn’t get hurt",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "so it’s not pointless",
  },
  {
    name: "Anil",
    color: "red",
    message: "i was watching a battle and i got a random mcafee popup",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "they didnt even try to make it real",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "rip bozo",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "what if i want to keep pod in tho..",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "We're not saying it's not pointless",
  },
  {
    name: "Chirag",
    color: "blue",
    message: "well some situations are better than others",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "Just something you need to play around or not use Goliso",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message:
      "goliso could have some niche starts or something with its ability idk",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "First impression is good imo",
  },
  {
    name: "Anil",
    color: "red",
    message: "yes",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "thats tru",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "First impression is nice, but it's pretty predictable.",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "but that’s the only thing it has going for it",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "how long goes dynamax last",
  },
  {
    name: "Chirag",
    color: "blue",
    message: "The thing about it’s ability is that it keeps golisopod alive",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "that pokemon is so cute",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message: "And the next Pokémon you switch in doesn’t get hurt",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "so it’s not pointless",
  },
  {
    name: "Anil",
    color: "red",
    message: "i was watching a battle and i got a random mcafee popup",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "they didnt even try to make it real",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "rip bozo",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "what if i want to keep pod in tho..",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "We're not saying it's not pointless",
  },
  {
    name: "Chirag",
    color: "blue",
    message: "well some situations are better than others",
  },
  {
    name: "Mukesh",
    color: "red",
    message: "Just something you need to play around or not use Goliso",
  },
  {
    name: "Anuradha",
    color: "skyblue",
    message:
      "goliso could have some niche starts or something with its ability idk",
  },
  {
    name: "Prabhas",
    color: "green",
    message: "First impression is good imo",
  },
  {
    name: "Anil",
    color: "red",
    message: "yes",
  },
  {
    name: "Sonam",
    color: "pink",
    message: "thats tru",
  },
  {
    name: "Suraj",
    color: "purple",
    message: "First impression is nice, but it's pretty predictable.",
  },
  {
    name: "Naeem",
    color: "seagreen",
    message: "but that’s the only thing it has going for it",
  },
  {
    name: "Dhananjay",
    color: "orange",
    message: "how long goes dynamax last",
  },
];

import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import StyledCheckbox from "./StyledCheckbox";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "280px",
    padding: "15px",
    borderRadius: "15px",
    backgroundColor: "#1c2560",
  },

  guest: {
    display: "flex",
    flexDirection: "column",
    "& div": {
      "& h6": {
        color: "#fff",
        fontSize: "20px",
        fontWeight: "600",
        fontFamily: "Montserrat",
        paddingLeft: "5px",
      },
    },
  },
  graphics: {
    borderTop: "1px solid #fff",
    "& h6": {
      color: "#fff",
      padding: "10px 0px",
    },
    "& div": {
      padding: "3px 0px",
      "& label": {
        color: "#fff",
        paddingRight: "10px",
      },
      "& p": {
        color: "#fff",
        paddingLeft: "5px",
      },
    },
  },
  chat: {
    borderTop: "1px solid #fff",
    "& h6": {
      color: "#fff",
      padding: "10px 0px",
    },
    "& div": {
      padding: "3px 0px",
      "& label": {
        color: "#fff",
        paddingRight: "5px",
      },
      "& p": {
        color: "#fff",
        paddingLeft: "5px",
      },
    },
  },
  choosename: {
    borderTop: "1px solid #fff",
    marginTop: "5px",
    paddingTop: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const graphicsdata = [
  {
    text: "Dark mode",
  },
  {
    text: "Disable animations",
  },
  {
    text: "Use BW sprites instead of XY models",
  },
  {
    text: "Use modern sprites for past generations",
  },
];
const chatdata = [
  {
    text: "Block PMs",
  },
  {
    text: "Block Challenges",
  },
  {
    text: "Show PMs in chat rooms",
  },
  {
    text: "Highlight when your name is said in chat",
  },
  {
    text: "Notifications disappear automatically",
  },
  {
    text: "Prompt on refresh",
  },
];

function Setting() {
  const [battlePop, setBattlePop] = useState(false);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className={classes.guest}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/User/user1.png" width="20%" />
          <Typography variant="h6">Guest 2865755</Typography>
        </Box>
        <Box style={{ padding: "15px" }}>
          <select onClick={() => setBattlePop(true)}>
            <option value="1">Change background</option>
          </select>
        </Box>
      </Box>
      <Box className={classes.graphics}>
        <Typography variant="h6"> Graphics</Typography>
        <Box>
          <label>Layout:</label>
          <select onClick={() => setBattlePop(true)}>
            <option value="1">Single Panel</option>
            <option value="2">Left and Right Panel</option>
          </select>
        </Box>
        <Box>
          <label>Background:</label>
          <select onClick={() => setBattlePop(true)}>
            <option value="1">Change background</option>
          </select>
        </Box>
        <Box>
          {graphicsdata.map((element) => (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <StyledCheckbox />
              <Typography variant="body2">{element.text}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.chat}>
        <Typography variant="h6">Chat</Typography>
        <Box>
          {chatdata.map((element) => (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <StyledCheckbox />
              <Typography variant="body2">{element.text}</Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <label>Language:</label>
          <select onClick={() => setBattlePop(true)}>
            <option value="1">Select Language</option>
          </select>
        </Box>
        <Box>
          <label>Tournaments:</label>
          <select onClick={() => setBattlePop(true)}>
            <option value="1">Notifications</option>
          </select>
        </Box>
        <Box>
          <label>Timestamps in chat rooms:</label>
          <select onClick={() => setBattlePop(true)}>
            <option value="1">Off</option>
            <option value="2">[HH:MM]</option>
            <option value="3">[HH:MM:SS]</option>
          </select>
        </Box>
        <Box>
          <label>Timestamps in PMs:</label>
          <select onClick={() => setBattlePop(true)}>
            <option value="1">Off</option>
            <option value="2">[HH:MM]</option>
            <option value="3">[HH:MM:SS]</option>
          </select>
        </Box>
        <Box>
          <label>Chat preferences: </label>
          <select onClick={() => setBattlePop(true)}>
            <option value="1"> Text formatting </option>
          </select>
        </Box>
      </Box>
      <Box className={classes.choosename}>
        <select onClick={() => setBattlePop(true)}>
          <option value="1">Choose Name</option>
        </select>
      </Box>
    </div>
  );
}

export default Setting;
